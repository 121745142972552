export const langFormator: Record<string, string> = {
  'zh-CN': 'zh_hans',
  'en-US': 'en',
  'ja-JP': 'ja',
  'es-ES': 'es',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'ru-RU': 'ru',
  'ko-KR': 'ko',
  'th-TH': 'th',
  'pt-PT': 'pt',
  'hi-IN': 'hi',
  zh: 'zh_hans',
  en: 'en',
  ja: 'ja',
  es: 'es',
  de: 'de',
  fr: 'fr',
  ru: 'ru',
  ko: 'ko',
  th: 'th',
  pt: 'pt',
  hi: 'hi',
};

export const langs = {
  'zh-CN': 'zh_hans',
  'zh-Hans': 'zh_hans',
  'en-US': 'en',
  'ja-JP': 'ja',
  'es-ES': 'es',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'it-IT': 'it',
  'ru-RU': 'ru',
  'ko-KR': 'ko',
  'th-TH': 'th',
  'nl-NL': 'nl',
  'id-ID': 'id',
  'pt-PT': 'pt',
  'tr-TR': 'tr',
  'uk-UA': 'uk',
  'vi-VN': 'vi',
  'hi-IN': 'hi',
};

export const SUPPORTED_LANGUAGES = [
  'en', 'ja', 'zh_hans', 'zh_hant', 'es', 'hi', 'pt', 'ru', 'fr',
  'de', 'id', 'tr', 'ko', 'vi', 'it', 'th', 'nl', 'uk', 'cs', 'lo',
] as const;

// 浏览器语言转自定语言
export const defaultLang = langFormator[navigator.language] || 'en';
