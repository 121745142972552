import React, { useState } from 'react';
import { Layout } from 'antd';
import MainContent from './components/MainContent';
import AiChatSidebar from './components/AiChatSidebar';

const { Content, Sider } = Layout;

const AnalysePage: React.FC = () => {
  const [showAiChat, setShowAiChat] = useState(false);

  const toggleAiChat = () => {
    setShowAiChat(!showAiChat);
  };

  return (
    <Layout style={{ height: '100vh', background: '#fff' }}>
      <Content>
        <MainContent onAiAnalyse={toggleAiChat} showAiChat={showAiChat} />
      </Content>
      <Sider
        theme="light"
        width={400}
        collapsed={!showAiChat}
        collapsedWidth={0}
        style={{
          background: '#f5f5f5',
          borderLeft: '1px solid #e8e8e8',
        }}
      >
        <AiChatSidebar />
      </Sider>
    </Layout>
  );
};

export default AnalysePage;
