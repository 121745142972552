import React, { useEffect, useState } from 'react';
import { Layout, Button, Tabs, Space, Dropdown, Typography } from 'antd';
import {
  ArrowLeftOutlined,
  RobotOutlined,
  DownloadOutlined,
  GlobalOutlined,
  CloseOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import './MainContent.css'; // 引入样式文件
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getFileStatus, getGeminiFile, getLogDetail } from '@/api/subtitles';
import RealTimeContent from './RealTimeContent';
import GeminiSummaryContent, { GeminiGenerateType } from './GeminiSummaryContent';
import GeminiTranscriptionContent from './GeminiTranscriptionContent';

interface MainContentProps {
  onAiAnalyse: () => void;
  showAiChat: boolean;
}

const LANGUAGE_MAP = {
  zh_hans: '中文',
  en: 'English',
  ja: '日本語',
};

type LanguageKey = keyof typeof LANGUAGE_MAP;

const MainContent: React.FC<MainContentProps> = ({ onAiAnalyse, showAiChat }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { subtitle_segment_id: subtitleSegmentId } = useParams();
  const { Header, Content } = Layout;
  const [currentLang, setCurrentLang] = useState<LanguageKey>('zh_hans');
  const [pageLoading, setPageLoading] = useState(false);
  const [roomId, setRoomId] = useState('');
  const { Link } = Typography;
  const [isFinish, setIsFinish] = useState(false);
  const [title, setTitle] = useState('');
  const [historyData, setHistoryData] = useState<API.SubtitleItem[] | undefined>(undefined);
  const [fileMergeId, setFileMergeId] = useState('');

  const init = async () => {
    if (!subtitleSegmentId) {
      return;
    }
    setPageLoading(true);
    const res = await getLogDetail(subtitleSegmentId).catch((err) => {
      console.log('..>> err', err);
    });
    if (res) {
      setTitle(res.data.data.title);
      setHistoryData(res.data.data.subtitle_list);
      setRoomId(res.data.data.room_id);
    }
    setPageLoading(false);
  };

  const getHistoryData = async (newRoomId: string) => {
    const { data: { data: { file_merge_id } } } = await getGeminiFile({
      roomId: newRoomId,
    });
    console.log('..>> file_merge_id', file_merge_id);
    setFileMergeId(file_merge_id);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const status = await getContentStatus(file_merge_id);
    setIsFinish(status);
  };

  const getContentStatus = async (fileId: string) => {
    const { data: { data: { status } } } = await getFileStatus({
      file_merge_id: fileId,
    });

    // FINISH\INIT
    console.log('..>> status', status);
    return status === 'FINISH';
  };

  useEffect(() => {
    console.log('..>> roomId change', roomId);
    if (roomId && roomId.length > 0) {
      // 获取历史数据
      getHistoryData(roomId);
    }
  }, [roomId]);

  useEffect(() => {
    init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subtitleSegmentId]);

  useEffect(() => {
    const storedLang = localStorage.getItem('preferred_language');
    // eslint-disable-next-line no-prototype-builtins
    if (storedLang && LANGUAGE_MAP.hasOwnProperty(storedLang)) {
      setCurrentLang(storedLang as LanguageKey);
    }
  }, []);

  // 语言切换
  const handleLanguageChange = (lang: LanguageKey) => {
    setCurrentLang(lang);
    localStorage.setItem('preferred_language', lang);
  };

  const languageItems: MenuProps['items'] = [
    {
      key: 'zh',
      label: '中文',
      onClick: () => handleLanguageChange('zh_hans'),
    },
    {
      key: 'en',
      label: 'English',
      onClick: () => handleLanguageChange('en'),
    },
    {
      key: 'ja',
      label: '日本語',
      onClick: () => handleLanguageChange('ja'),
    },
  ];

  return (
    <Layout style={{ background: '#fff' }}>
      <Header style={{
        background: '#fff',
        padding: '0 24px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #e8e8e8',
      }}>
        <Space>
          <Button
            className="analyse-page-btn"
            icon={<ArrowLeftOutlined />}
            type="text"
            onClick={() => {
              navigate('/record');
            }}
          />
          <h2 style={{ margin: 0, color: '#333333' }}>{title}</h2>
        </Space>
        <Space>
          <Space style={{ color: isFinish ? '#8c8c8c' : '#bfbfbf' }}>
            {isFinish ? (
              <>
                <CheckCircleOutlined />
                <span>分析完成</span>
              </>
            ) : (
              <>
                <LoadingOutlined />
                <span>正在分析中</span>
              </>
            )}
          </Space>
          <Link
            className="analyse-page-btn"
            onClick={() => {
              // 处理下载逻辑
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              color: '#8c8c8c',
            }}
          >
            <DownloadOutlined style={{ marginRight: 4 }} />
            下载记录
          </Link>
          <Button
            className="analyse-page-btn"
            type={showAiChat ? 'primary' : 'default'}
            icon={showAiChat ? <CloseOutlined /> : <RobotOutlined />}
            onClick={onAiAnalyse}
          >
            {showAiChat ? '关闭AI分析' : 'AI分析'}
          </Button>
          <Dropdown menu={{ items: languageItems }} placement="bottom">
            <Button
              className="analyse-page-btn"
              icon={<GlobalOutlined />}
            >
              {LANGUAGE_MAP[currentLang]}
            </Button>
          </Dropdown>
          {/*  TODO: 更多功能 
          <Button
            className="analyse-page-btn"
            icon={<MoreOutlined />}
          /> */}
        </Space>
      </Header>
      <Content style={{ padding: '24px' }}>
        <Tabs
          defaultActiveKey="record"
          items={[
            {
              key: 'record',
              label: '会议总结(多模态)',
              children: <GeminiSummaryContent
                isFinish={isFinish}
                fileMergeId={fileMergeId} generateType={GeminiGenerateType.SUMMARY} targetLang={currentLang} />,
            },
            {
              key: 'translation',
              label: '会议记录（多模态）',
              children: <GeminiTranscriptionContent
                isFinish={isFinish}
                fileMergeId={fileMergeId} generateType={GeminiGenerateType.TRANSLATE} targetLang={currentLang} />,
            },
            {
              key: 'summary',
              label: '会议记录(原始)',
              children: <RealTimeContent roomId={roomId} historyData={historyData} />,
            },
          ]}
        />
      </Content>
    </Layout>
  );
};

export default MainContent;
