import React from 'react';
import { Layout, Input, Button, List, Space } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const { Content, Footer } = Layout;

const AiChatSidebar: React.FC = () => (
    <Layout style={{ height: '100%', background: '#f5f5f5' }}>
      <Content style={{ padding: '24px', overflowY: 'auto' }}>
        <List>
          {/* 这里可以添加聊天消息列表 */}
        </List>
      </Content>
      <Footer style={{
        padding: '12px 24px',
        background: '#fff',
        borderTop: '1px solid #e8e8e8',
      }}>
        <Space.Compact style={{ width: '100%' }}>
          <Input
            style={{ width: 'calc(100% - 40px)' }}
            placeholder="输入您的问题..."
          />
          <Button
            icon={<SendOutlined />}
            type="primary"
          />
        </Space.Compact>
      </Footer>
    </Layout>
);

export default AiChatSidebar;
