import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import ReactMarkdown from 'react-markdown';
import { createGeminiResult, queryGeminiTranscriptionResult } from '@/api/subtitles';
import './GeminiContent.less';

// 定义生成类型枚举
export enum GeminiGenerateType {
  SUMMARY = 'SUMMARY_PROMPT',
  TRANSLATE = 'TRANSCRIPTION_PROMPT',
}

interface GeminiContentProps {
  isFinish: boolean;
  fileMergeId: string;
  generateType: GeminiGenerateType;
  targetLang: string;
}

const GeminiTranscriptionContent: React.FC<GeminiContentProps> = ({ isFinish, fileMergeId, generateType, targetLang }) => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<API.GeminiTranscriptionResultResponse | null>(null);

  const fetchGeminiResult = async () => {
    try {
      setLoading(true);

      // 首先查询现有结果
      const { data: { data: { generate_transcription_list } } } = await queryGeminiTranscriptionResult({
        file_merge_id: fileMergeId,
      });

      if (!generate_transcription_list || generate_transcription_list.length === 0) {
        // 如果没有结果，调用创建接口
        const { data: { data: { multimodal_summary_details: createResult } } } = await createGeminiResult({
          file_merge_id: fileMergeId,
          prompt_type: 'TRANSCRIPTION_PROMPT',
          target_lang: targetLang,
        });
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        createResult && setTimeout(() => {
          fetchGeminiResult();
        }, 500);
      } else {
        // 如果有结果，直接使用查询结果
        setContent({ generate_transcription_list });
      }
    } catch (error) {
      message.error('获取分析结果失败');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const updateGeminiResult = async () => {
    const { data: { data: { multimodal_summary_details: createResult } } } = await createGeminiResult({
      file_merge_id: fileMergeId,
      prompt_type: 'TRANSCRIPTION_PROMPT',
      target_lang: targetLang,
    });
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    createResult && setTimeout(() => {
      fetchGeminiResult();
    }, 500);
  };

  useEffect(() => {
    if (isFinish && fileMergeId && fileMergeId.length > 0) {
      fetchGeminiResult();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileMergeId, generateType, isFinish]);

  if (!isFinish) {
    return (
      <div className="gemini-content">
        <div className="loading-container">
          <div className="loading-text">会议进行中, 请稍后再来查看结果...</div>
        </div>
      </div>
    );
  }

  return (
    <div className="gemini-content">
      {loading ? (
        <div className="loading-container">
          <div className="loading-text">
            {generateType === GeminiGenerateType.SUMMARY ? '正在总结中...' : '正在翻译中...'}
          </div>
        </div>
      ) : (
        <div className="content-container">
          <div className="markdown-content-title">
            {generateType === GeminiGenerateType.SUMMARY ? '会议总结' : '会议记录'}
            <Button
              type="text"
              className="analyse-page-btn"
              icon={<SyncOutlined />}
              onClick={updateGeminiResult}
              style={{ float: 'right', marginTop: '-4px' }}
            />
          </div>
          <div className="markdown-content">
            {content?.generate_transcription_list?.map((item: { source: string; speaker: string; target_text: string }, key: number) => (
              <div key={key} className="transcript-row">
                <div className="speaker-column">
                  <span>{item.speaker}:</span>
                </div>
                <div className="content-column">
                  <div className="source-text">{item.source}</div>
                  <div className="target-text">{item.target_text}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default GeminiTranscriptionContent;
