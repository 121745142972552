import React, { useEffect, useMemo, useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button } from 'antd';
import styles from './aisidebar.module.less';
import { useTranslation } from 'react-i18next';
import { getHistory, getSummary } from '@/api/subtitles';

interface AISidebarProps {
  visible: boolean;
  onClose: () => void;
  segmentId?: string;
}

const AISidebar: React.FC<AISidebarProps> = ({ visible, onClose, segmentId }: AISidebarProps) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;

  console.log(language);
  const [loading, setLoading] = useState(false);
  const languageMap: Record<string, string> = {
    'zh-CN': 'zh_hans',
    'zh-TW': 'zh_hant',
    'en-US': 'en',
    'ja-JP': 'ja',
    'ja': 'ja',
    'en': 'en',
    'zh': 'zh_hans',
  };

  const mappedLanguage = useMemo(() => {
    return languageMap[language] || 'en';
  }, [language]);

  const [summary, setSummary] = useState<string>('this is a summary');
  const [history, setHistory] = useState<API.SummaryDetails[]>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!segmentId) {
      return;
    }

    getHistory({ user_subtitle_segment_id: segmentId }).then((res) => {
      setHistory(res.data.data.summary_details_list);
    });
  }, [segmentId]);

  useEffect(() => {
    if (history.length > 0 && chatContainerRef.current && visible) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [history, visible]);

  const fetchSummary = async () => {
    if (!segmentId) {
      return;
    }

    const res = await getSummary({ user_subtitle_segment_id: segmentId, target_lang: mappedLanguage });
    console.log(res.data.data.summary_details);
    setSummary(res.data.data.summary_details.summary_text);
    setHistory([...history, res.data.data.summary_details]);
  };

  return (
    <div className={`${styles.sidebar} ${visible ? styles.visible : ''}`}>
      <div className={styles.sidebarHeader}>
        <h3>{t('ai.summary')}</h3>
      </div>
      
      <div className={styles.chatContainer} ref={chatContainerRef}>
        <div className={styles.chatContent}>
          {history.map((item, index) => (
            <p key={index} className={styles.chatItem}>
              <ReactMarkdown>{item.summary_text}</ReactMarkdown>
            </p>
          ))}
        </div>
      </div>
      
      <div className={styles.inputArea}>
        <textarea 
          placeholder={t('ai.inputPlaceholder')}
          className={styles.chatInput}
          style={{ display: 'none' }}
        />
        <Button type="primary" style={{ display: 'none' }}>{t('ai.send')}</Button>
        <Button type="primary" style={{ marginLeft: '10px' }} onClick={() => {
          setLoading(true);
          fetchSummary().finally(() => {
            setLoading(false);
          });
        }} loading={loading}>{t('ai.summary')}</Button>
        <Button type="default" style={{ float: 'right' }} onClick={onClose}>
          {t('ai.close')}
        </Button>
      </div>
      
    </div>
  );
};

export default AISidebar;
