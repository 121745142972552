/* eslint-disable @typescript-eslint/no-use-before-define */
import { PlusOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Input, message, Modal, Popconfirm, Space, Table, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import css from './style.module.less';
import { getTermBaseDetailInfo, getTermBaseDetailList, updateTermBaseDetail, uploadTermBase } from '@/api/subtitles';
import csvContent from './Terminology_Template.csv?raw';


interface TermDetailInfo {
  noun_library_id: string;
  library_name: string;
  source_language: string;
  status: 'ON' | 'OFF';
  target_language: string;
}
interface TermDetailList {
  noun_library_id: string;
  noun_size?: number;
  source_noun_list: string[];
  target_noun_list: string[];
}

interface TermItem {
  key: string;
  source_term: string;
  target_term: string;
}

// 术语库详情页面组件
const TermBaseDetailPage: React.FC = () => {
  // 路由参数
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  // 状态管理
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [updateTimes, setUpdateTimes] = useState(0);

  // 术语库基本信息状态
  const [termDetailInfo, setTermDetailInfo] = useState<TermDetailInfo>({
    noun_library_id: '',
    library_name: '',
    source_language: '',
    status: 'OFF',
    target_language: '',
  });

  // 术语列表数据状态
  const [termDetailList, setTermDetailList] = useState<TermDetailList>({
    noun_library_id: '',
    noun_size: 0,
    source_noun_list: [],
    target_noun_list: [],
  });

  // 增加页面加载状态
  const [pageLoading, setPageLoading] = useState(true);
  const [loadError, setLoadError] = useState<string | null>(null);

  // 增加删除loading状态
  const [deletingKeys, setDeletingKeys] = useState<string[]>([]);

  // 转换数据为表格格式
  const tableData: TermItem[] = termDetailList.source_noun_list.map((source, index) => ({
    key: index.toString(),
    source_term: source,
    target_term: termDetailList.target_noun_list[index],
  }));

  // 表格列配置
  const columns = [
    {
      title: t('termbase.key'),
      dataIndex: 'key',
      key: 'key',
      render: (text: string) => Number(text) + 1,
    },
    {
      title: `${t('termbase.sourceTerm')} (${t(`lang.${termDetailInfo.source_language}`)})`,
      dataIndex: 'source_term',
      key: 'source_term',
    },
    {
      title: `${t('termbase.targetTerm')} (${t(`lang.${termDetailInfo.target_language}`)})`,
      dataIndex: 'target_term',
      key: 'target_term',
    },
    {
      title: t('termbase.action'),
      key: 'action',
      render: (_: any, record: TermItem) => (
        <Space size="middle">
          <a onClick={() => handleEdit(record)}>{t('termbase.editTerm')}</a>
          <Popconfirm
            title={t('common.deleteWarn')}
            onConfirm={() => handleDelete(record.key)}
            okText={t('common.confirm')}
            cancelText={t('common.cancel')}
            disabled={deletingKeys.includes(record.key)}
          >
            <a style={{
              color: 'red',
              cursor: deletingKeys.includes(record.key) ? 'not-allowed' : 'pointer',
              opacity: deletingKeys.includes(record.key) ? 0.5 : 1
            }}>
              {deletingKeys.includes(record.key) ? t('common.deleting') : t('termbase.deleteTerm')}
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  // 初始化加载数据
  useEffect(() => {
    if (id) {
      setPageLoading(true);
      setLoadError(null);

      // 使用 Promise.all 同时获取两个数据
      Promise.all([
        getTermBaseDetailInfo({ noun_library_id: id }),
        getTermBaseDetailList({ noun_library_id: id }),
      ]).then(([infoRes, listRes]) => {
        setTermDetailInfo({ ...termDetailInfo, ...infoRes.data.data.noun_library });
        setTermDetailList({ ...termDetailList, ...listRes.data.data });
      }).catch(() => {
        setLoadError(t('termbase.fetchFailed'));
        message.error(t('termbase.fetchFailed'));
      }).finally(() => {
        setPageLoading(false);
      });
    }
  }, [id, updateTimes]);

  // 编辑术语数据处理
  const editTermData = (source_term: string, target_term: string, key: number) => {
    const source_noun_list = [...termDetailList.source_noun_list];
    const target_noun_list = [...termDetailList.target_noun_list];

    // 判断是新增还是编辑
    if (!Number.isNaN(key)) {
      source_noun_list[key] = source_term;
      target_noun_list[key] = target_term;
    } else {
      source_noun_list.push(source_term);
      target_noun_list.push(target_term);
    }

    return {
      noun_library_id: id || '',
      source_noun_list,
      target_noun_list,
    };
  };

  // 更新术语数据到服务器
  const updateTermData = async (data: TermDetailList) => new Promise((resolve, reject) => {
    setModalIsLoading(true);
    if (id) {
      updateTermBaseDetail({
        noun_library_id: id,
        source_noun_list: data.source_noun_list,
        target_noun_list: data.target_noun_list,
      })
        .then(() => {
          message.success(t('termbase.updateSuccess'));
          setTermDetailList(data);
          resolve(true);
        })
        .catch(() => {
          message.error(t('termbase.updateFailed'));
          reject(new Error(t('termbase.updateFailed')));
        })
        .finally(() => {
          setModalIsLoading(false);
        });
    }
  });

  // 检查术语数量
  const checkTermCount = () => {
    if (termDetailList.source_noun_list.length >= 1000) {
      message.error(t('termbase.termBaseDetailMax'));
      return false;
    }
    return true;
  };

  // 用户操作处理函数
  const handleCreate = () => {
    if (!checkTermCount()) {
      return;
    }
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleEdit = (record: TermItem) => {
    console.log('编辑术语:', record);
    form.setFieldsValue({
      key: record.key,
      source_term: record.source_term,
      target_term: record.target_term,
    });
    if (!id) {
      return;
    }
    // updateTermData(editTermData(record.source_term, record.target_term, Number(record.key)));
    setIsModalOpen(true);
  };

  const handleDelete = async (key: string) => {
    setDeletingKeys((prev) => [...prev, key]);

    try {
      const source_noun_list = [...termDetailList.source_noun_list];
      const target_noun_list = [...termDetailList.target_noun_list];
      source_noun_list.splice(Number(key), 1);
      target_noun_list.splice(Number(key), 1);

      await updateTermData({
        ...termDetailList,
        source_noun_list,
        target_noun_list,
      });

      message.success(t('termbase.deleteSuccess'));
    } catch (error) {
      message.error(t('termbase.deleteFailed'));
    } finally {
      setDeletingKeys((prev) => prev.filter(k => k !== key));
    }
  };

  // 模态框操作处理
  const handleModalOk = () => {
    form.validateFields().then((values) => {
      if (!values.key && !checkTermCount()) {
        return;
      }
      // 这里添加创建/编辑术语的API调用
      console.log('提交的值:', values);
      if (id) {
        updateTermData(editTermData(values.source_term, values.target_term, Number(values.key))).then(() => {
          setIsModalOpen(false);
          form.resetFields();
        });
      }
    });
  };

  const handleSaveAndContinue = () => {
    form.validateFields().then((values) => {
      if (!values.key && !checkTermCount()) {
        return;
      }
      if (id) {
        updateTermData(editTermData(values.source_term, values.target_term, Number(values.key)));
      }
      form.resetFields();
    });
  };

  // 下载模板函数
  const handleDownloadTemplate = () => {
    // 添加 BOM 标记以确保 Excel 正确识别 UTF-8 编码
    const BOM = '\uFEFF';
    const blob = new Blob([BOM + csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'Terminology_Template.csv';
    link.click();
  };

  const errorMessage = (m?: string) => {
    // 超过1000条
    if (m?.includes('exceeds the limit')) {
      return t('termbase.termBaseDetailMax');
    }
    return t('termbase.batchUploadFailed');
  };
  // 处理文件上传
  const handleFileUpload = async (file: File) => {
    setUploadLoading(true);
    try {
      // 读取文件内容并确保使用 UTF-8 编码
      const reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      await new Promise((resolve, reject) => {
        reader.onload = async () => {
          try {
            await uploadTermBase({
              noun_library_id: id || '',
              file,
            });
            message.success(t('termbase.batchUploadSuccess'));
            resolve(true);
          } catch (error) {
            reject(error);
          }
        };
        reader.onerror = () => reject(new Error('文件读取失败'));
      });
    } catch (error: any) {
      console.error('批量上传失败:', error.response.data.message);
      message.error(errorMessage(error.response.data.message));
    } finally {
      setUploadLoading(false);
      setIsUploadModalOpen(false);
      setSelectedFile(null);
      // 重新加载列表
      setUpdateTimes(updateTimes + 1);
    }
  };

  // 处理文件选择
  const handleFileSelect = (file: File) => {
    setSelectedFile(file);
    return false; // 阻止自动上传
  };

  // 处理确认上传
  const handleConfirmUpload = () => {
    if (selectedFile) {
      handleFileUpload(selectedFile);
    }
  };

  // 处理取消上传
  const handleCancelUpload = () => {
    setSelectedFile(null);
    setIsUploadModalOpen(false);
  };

  return (
    <div className={css.pageContent}>
      {loadError ? (
        <div className={css.errorState}>
          <div className={css.errorMessage}>{t('termbase.fetchFailed')}</div>
          <Button onClick={() => window.location.reload()}>
            {t('common.retry')}
          </Button>
        </div>
      ) : (
        <>
          <div className={css.pageHeader}>
            <div className={css.titleSection}>
                {pageLoading ? <div className={css.loading}>{t('common.loading')}</div> : (
                  <div>
                    <Breadcrumb
                      className={css.breadcrumb}
                      items={[
                        { title: <Link to="/termbase">{t('termbase.name')}</Link> },
                        { title: termDetailInfo.library_name },
                      ]}
                    />
                    <div className={css.description}>
                      {t(`lang.${termDetailInfo.source_language}`)} - {t(`lang.${termDetailInfo.target_language}`)}
                    </div>
                  </div>
                )}
                <Space className={css.buttonGroup}>
                  <Button className={css.createButton} type="primary" icon={<PlusOutlined />} onClick={handleCreate}>
                    {t('termbase.termBaseCreate')}
                  </Button>
                  <Button icon={<UploadOutlined />} onClick={() => setIsUploadModalOpen(true)}>
                    {t('termbase.batchUpload')}
                  </Button>
                </Space>
            </div>
          </div>

          <Table
            className={css.contentTable}
            columns={columns}
            dataSource={tableData}
            loading={pageLoading}
          />

          <Modal
            title={form.getFieldValue('key') ? t('termbase.editTerm') : t('termbase.termBaseCreate')}
            open={isModalOpen}
            onOk={handleModalOk}
            onCancel={() => setIsModalOpen(false)}
            footer={[
              <Button key="cancel" onClick={() => setIsModalOpen(false)}>
                {t('common.cancel')}
              </Button>,
              <Button key="submit" type="primary" onClick={handleModalOk} loading={modalIsLoading}>
                {t('common.confirm')}
              </Button>,
              <Button key="saveAndContinue"
                style={{ display: form.getFieldValue('key') ? 'none' : 'inline-block' }}
                type="primary"
                loading={modalIsLoading}
                onClick={handleSaveAndContinue}
              >
                {t('termbase.saveAndContinue')}
              </Button>,
            ]}
          >
              <Form form={form}
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 16 }}
              >
                <Form.Item name="key" hidden={true}>
                  <Input />
              </Form.Item>

              <Form.Item
                name="source_term"
                label={`${t('termbase.sourceTerm')} (${t(`lang.${termDetailInfo.source_language}`)})`}
                rules={[{ required: true, message: t('termbase.sourceTermRequired') }]}
              >
                <Input placeholder={t('termbase.sourceTermPlaceholder')} />
              </Form.Item>

              <Form.Item
                name="target_term"
                label={`${t('termbase.targetTerm')} (${t(`lang.${termDetailInfo.target_language}`)})`}
                rules={[{ required: true, message: t('termbase.targetTermRequired') }]}
              >
                <Input placeholder={t('termbase.targetTermPlaceholder')} />
              </Form.Item>
            </Form>
          </Modal>

          <Modal
            title={t('termbase.batchUpload')}
            open={isUploadModalOpen}
            onCancel={handleCancelUpload}
            footer={[
              <Button key="cancel" onClick={handleCancelUpload}>
                {t('common.cancel')}
              </Button>,
              <Button
                key="upload"
                type="primary"
                disabled={!selectedFile}
                loading={uploadLoading}
                onClick={handleConfirmUpload}
              >
                {t('common.confirm')}
              </Button>
            ]}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                {t('termbase.downloadTemplateHint')}
                <a onClick={handleDownloadTemplate}>{t('termbase.downloadTemplateLink')}</a>
              </div>
              <Upload.Dragger
                accept=".csv"
                showUploadList={true}
                maxCount={1}
                fileList={selectedFile ? [selectedFile] : []}
                beforeUpload={handleFileSelect}
                onRemove={() => setSelectedFile(null)}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text">
                  {t('termbase.dragOrClickUpload')}
                </p>
                <p className="ant-upload-hint">
                  {t('termbase.uploadHint')}
                </p>
              </Upload.Dragger>
            </Space>
          </Modal>
        </>
      )}
    </div>
  );
};

export default TermBaseDetailPage;
