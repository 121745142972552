import { useState, useEffect, useRef } from 'react';
import { Modal, Spin, Tooltip, Dropdown, Button, FloatButton } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import dayjs from 'dayjs';
import type { ItemType } from 'antd/lib/menu/hooks/useItems';
import SvgIcon from '@/components/SvgIcon';
import { deleteLog, downloadLog, getLogDetail, updateLogTitle, updateOriginalText } from '@/api/subtitles';
import { downloadFile } from '@/utils';
import useSets from '@/hooks/useSets';
import SubtitleItem from '@/components/record/subtitleItem';
import TitleItem from '@/components/record/titleItem'
import styles from '@/components/style/detail.module.less';
import pagestyles from '@/components/style/index.module.less';
import modalStyles from '@/components/style/modal.module.less';
import useSubtitleMqtt from '@/hooks/useSubtitleMqtt';
import AISidebar from './aisidebar';
import RobotIcon from "@/assets/icons/robot.svg"

function Detail() {
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const scrollRef = useRef(null);
  const [isManualScroll, setIsManualScroll] = useState(false);
  const [title, setTitle] = useState('');
  const [logData, setLogData] = useState<API.SubtitleItem[] | undefined>(undefined);
  const [loadingSegment, addLoadingSegment, removeLoadingSegment] = useSets();
  const [sidebarVisible, setSidebarVisible] = useState(false);

  useEffect(() => {
    if (!isManualScroll) {
      scrollToBottom();
    }
  }, [logData, isManualScroll]);
  // 滚动到底部的函数
  const scrollToBottom = () => {
    const scroll = scrollRef.current;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  };

  const getLogDetailRequest = useQuery({
    queryKey: ['getLogDetail', params.id],
    queryFn: () => {
      if (!params.id) {
        return;
      }

      // eslint-disable-next-line consistent-return
      return getLogDetail(params.id).then((res) => {
        setTitle(res.data.data.title);
        setLogData(res.data.data.subtitle_list);

        return res;
      });
    },
  });

  const data = getLogDetailRequest.data?.data.data;

  useSubtitleMqtt({
    roomId: data?.room_id,
    onMessage: (msg) => {
      console.log('>> onMessage', msg);
      setLogData((prevData) => {
        if (!prevData) {
          return prevData;
        }

        const nextData = [...prevData];
        const index = nextData.findIndex((item) => item.subtitle_id === msg.subtitle_id);

        if (index > -1) {
          nextData[index] = msg;
        } else {
          nextData.push(msg);
        }

        return nextData;
      });
    },
  });

  const downloadLogRequest = useQuery({
    enabled: false,
    queryKey: ['downloadLog', params.id],
    queryFn: () => {
      if (!params.id) {
        return;
      }

      const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // eslint-disable-next-line consistent-return
      return downloadLog({
        user_subtitle_segment_id: params.id,
        zone,
      }).then((res) => {
        if (res.data.data) {
          const date = dayjs(data?.gmt_create).format('YYYY-MM-DD');
          downloadFile(res.data.data, `${title}-${date}.txt`);
        }
      });
    },
  });

  const updateLogTitleRequest = (value: string) => {
    if (!params.id) {
      return;
    }

    updateLogTitle({
      user_subtitle_segment_id: params.id,
      title: value,
    });
  };

  const onBack = () => {
    if (location.state?.from === 'record') {
      navigate(-1);
    } else {
      navigate('/record');
    }
  };

  const onDownload = () => {
    if (!params.id) {
      return;
    }

    downloadLogRequest.refetch();
  };

  const onDelete = () => {
    Modal.confirm({
      className: modalStyles.modal,
      title: t('removeSubtitle'),
      icon: <SvgIcon className={modalStyles.icon} name="danger-solid" />,
      content: <p>{t('removeSubtitleTips')}</p>,
      onOk: () => {
        if (!params.id) {
          return;
        }

        // eslint-disable-next-line consistent-return
        return deleteLog(params.id).then(() => {
          onBack();
        });
      },
    });
  };

  const onUpdateTitle = (value: string) => {
    setTitle(value);
    updateLogTitleRequest(value);
  };

  const menuItems: ItemType[] = [
    {
      key: 'account',
      className: styles.dropdownMenuItem,
      icon: (
        <Tooltip placement="bottom" title={t('removeSubtitle')}>
          <SvgIcon name="delete" />
        </Tooltip>
      ),
      onClick: async () => {
        onDelete();
      },
    },
  ];

  // 更新某一条字幕的原文和翻译
  const updateSegmentTranslateText = (subtitle_id: string, text: string, translate_text: string) => {
    setLogData((prevData) => {
      if (!prevData) {
        return prevData;
      }

      const nextData = [...prevData];
      const nextItemIndex = nextData.findIndex((item) => item.subtitle_id === subtitle_id);

      if (nextItemIndex > -1) {
        const { subtitle_translation_list } = nextData[nextItemIndex];

        if (subtitle_translation_list) {
          subtitle_translation_list[0].text = translate_text;
        }

        nextData[nextItemIndex] = {
          ...nextData[nextItemIndex],
          text,
          subtitle_translation_list,
        };
      }

      return nextData;
    });
  };

  const onSaveSubtitle = (item: API.SubtitleItem, text: string) => {
    const { subtitle_id, subtitle_translation_list } = item;

    addLoadingSegment(subtitle_id);

    const translateItem = subtitle_translation_list?.[0];

    return updateOriginalText({
      subtitle_id,
      original_text: text,
      room_id: data?.room_id || '',
      target_lang: translateItem?.lang,
    }).then((res) => {
      if (res.data.status === 200) {
        const { subtitle_id: id, translate_text } = res.data.data;
        updateSegmentTranslateText(id, text, translate_text);
      }
    }).finally(() => {
      removeLoadingSegment(subtitle_id);
    });
  };
  const handleWheel = (event:any) => {
    if (event.deltaY > 0) {
      const scroll = scrollRef.current;
      if (scroll) {
        const isUserScrollUp = scroll.scrollTop < (scroll.scrollHeight - scroll.offsetHeight-2);
        setIsManualScroll(isUserScrollUp);
      }
    } else if (event.deltaY < 0) {
      setIsManualScroll(true);
    }
  };
  return (
    <div className={pagestyles.pageContent}>
      <div className={pagestyles.pageTitle}>
      <Link className={pagestyles.link} to="/record"><SvgIcon name="arrow-left" style={{ width: '20px', height: '20px' }} /></Link>
        <div className={pagestyles.titleText}>
          <TitleItem text={title} onSave={onUpdateTitle}/>
        </div>
        <div className={pagestyles.pageTitleRight}>
          <div className={pagestyles.iconItem} onClick={onDownload}>
            <Tooltip placement="bottom" title={t('exportSubtitle')}>
              <SvgIcon name="download" />
            </Tooltip>
          </div>
          {/* <div className={pagestyles.iconItem}>
            <Tooltip placement="bottom">
              <SvgIcon name="export"/>
            </Tooltip>
          </div> */}
          <div className={pagestyles.iconItem}>
            <Dropdown menu={{ items: menuItems }} overlayClassName={styles.dropdownCustom} placement="bottomRight">
              <SvgIcon name="menu" />
            </Dropdown>
          </div>

        </div>
      </div>
      <div className={styles.subtitlesdetail} ref={scrollRef} onWheel={handleWheel}>
        {getLogDetailRequest.isLoading ? (
          <main className={styles.body}>
            <Spin className="!block mx-auto" size="large" />
          </main>
        ) : (
          <main className={styles.body} style={{ marginRight: sidebarVisible ? '400px' : '4px' }}>
            <div className={styles.desc}>
              <em className={clsx(styles.from, data && styles[data.source.toLocaleLowerCase().replace('_', '-')])}>
                {data?.source.replace('_', ' ')}
              </em>
              <a className={styles.url} href={data?.source_url} target="_blank">
                {data?.source_url}
              </a>
            </div>
            <div className={styles.time}>
              {dayjs(data?.gmt_create).format('YYYY-MM-DD HH:mm:ss')}
            </div>
            <article >
              {logData && logData.map((item) => {
                const subText = item.subtitle_translation_list?.[0].text;
                return (
                  <SubtitleItem
                    key={item.subtitle_id}
                    className={styles.subtitle}
                    textClassName={styles.original}
                    subTextClassName={styles.translateText}
                    text={item.text}
                    subText={subText}
                    loading={loadingSegment.includes(item.subtitle_id)}
                    onSave={(text) => onSaveSubtitle(item, text)}
                  />
                );
              })}
            </article>
          </main>
        )}
      </div>
      
      <FloatButton
      className={styles.floatButton}
      style={{insetInlineEnd: 64}}
      icon={<img src={RobotIcon} alt="robot" style={{ width: '32px', height: '32px' }} />}
      onClick={() => setSidebarVisible(true)} />

      <AISidebar 
        visible={sidebarVisible}
        segmentId={params.id}
        onClose={() => setSidebarVisible(false)}
      />

      {/* <FloatButton.Group
        trigger="hover"
        type="default"
        style={{ insetInlineEnd: 64 }}
        icon={<img src={RobotIcon} alt="robot" style={{ width: '20px', height: '20px' }} />}
        badge={{ dot: true }}
      >
        <FloatButton onClick={() => setSidebarVisible(true)} />
      </FloatButton.Group> */}
    </div>
  );
}

export default Detail;
