import { useEffect, useState } from 'react';
import styles from './style.module.less';
import useSubtitleMqtt from '@/hooks/useSubtitleMqtt';

export default function RealTimeContent({ roomId, historyData }: { roomId: string, historyData: API.SubtitleItem[] }) {
  const [messages, setMessages] = useState<API.SubtitleItem[] | undefined>(historyData);

  useSubtitleMqtt({
    roomId,
    onMessage: (msg) => {
      console.log('>> onMessage', msg);
      setMessages((prevData) => {
        if (!prevData) {
          return prevData;
        }

        const nextData = [...prevData];
        const index = nextData.findIndex((item) => item.subtitle_id === msg.subtitle_id);

        if (index > -1) {
          nextData[index] = msg;
        } else {
          nextData.push(msg);
        }

        return nextData;
      });
    },
  });

  useEffect(() => {
    console.log('..////////');
    // setMessages(historyData);
  }, [historyData]);

  return (
        <article>
            {messages
                && messages.map((item) => {
                  const subText = item.subtitle_translation_list?.[0].text;
                  return (
                      <div key={item.subtitle_id} className={styles.subtitle}>
                          <p className={styles.original} >
                              {item.text}
                          </p>
                          {subText && <p className={styles.translateText}>{subText}</p>}
                      </div>
                  );
                })}
        </article>
  );
}
