/* eslint-disable @typescript-eslint/no-use-before-define */
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Form, Input, Modal, Space, Switch, Table, Select, message, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import css from './style.module.less';
import { getTermBaseList, createTermBase, updateTermBase, deleteTermBase } from '@/api/subtitles';
import { SUPPORTED_LANGUAGES } from '@/utils/language';

interface TermBase {
  noun_library_id: string;
  library_name: string;
  source_language: string;
  target_language: string;
  status: 'ON' | 'OFF';
}

const TermBasePage: React.FC = () => {
  const { t } = useTranslation();

  // 状态管理
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState<TermBase[]>([]);

  // 添加页面 loading 状态
  const [pageLoading, setPageLoading] = useState(true);

  // Modal 相关状态
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIsLoading, setModalIsLoading] = useState(false);
  const [editingId, setEditingId] = useState<string | null>(null);

  // 语言选择相关状态
  const [sourceLanguage, setSourceLanguage] = useState<string>('');
  const [loadId, setLoadId] = useState<string | null>(null);

  // 添加删除loading状态
  const [deleteLoadingId, setDeleteLoadingId] = useState<string | null>(null);

  // 获取术语库列表数据
  const fetchTermBaseList = async () => {
    setPageLoading(true);
    try {
      const res = await getTermBaseList();
      setDataSource(res.data.data.noun_library_list);
    } catch (error) {
      message.error(t('termbase.fetchFailed'));
    } finally {
      setPageLoading(false);
    }
  };

  useEffect(() => {
    fetchTermBaseList();
  }, []);

  // Modal 相关处理函数
  const handleCreate = () => {
    // 检查是否超过10条

    if (dataSource.length >= 10) {
      message.error(t('termbase.termBaseMax'));
      return;
    }
    setEditingId(null);
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleEdit = (record: TermBase) => {
    setEditingId(record.noun_library_id);
    form.setFieldsValue({
      library_name: record.library_name,
      source_language: record.source_language,
      target_language: record.target_language,
      status: record.status === 'ON',
    });
    setIsModalOpen(true);
  };

  // 处理表单提交
  const handleModalOk = async () => {
    try {
      const values = await form.validateFields();
      setModalIsLoading(true);

      if (editingId) {
        await handleUpdateTermBase(editingId, values);
      } else {
        await handleCreateTermBase(values);
      }

      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      message.error(editingId ? t('termbase.editFailed') : t('termbase.createFailed'));
    } finally {
      setModalIsLoading(false);
    }
  };

  // 处理创建术语库
  const handleCreateTermBase = async (values: any) => {
    await createTermBase({
      library_name: values.library_name,
      source_language: values.source_language,
      target_language: values.target_language,
    });
    await fetchTermBaseList(); // 重新获取列表
  };

  // 处理更新术语库
  const handleUpdateTermBase = async (id: string, values: any) => {
    await updateTermBase({
      noun_library_id: id,
      library_name: values.library_name,
      status: values.status ? 'ON' : 'OFF',
    });

    setDataSource(dataSource.map((item) => (item.noun_library_id === id
      ? {
        ...item,
        library_name: values.library_name,
        status: values.status ? 'ON' : 'OFF',
      }
      : item)));
  };

  // 处理开关状态变化
  const handleSwitchChange = async (record: TermBase, checked: boolean) => {
    // 检查是否已经有3条处于开启状态
    if (checked) {
      const openCount = dataSource.filter((item) => item.status === 'ON').length;
      if (openCount >= 3) {
        message.error(t('termbase.termBaseMaxOpenTips'));
        return;
      }
    }
    setLoadId(record.noun_library_id);
    try {
      await updateTermBase({
        noun_library_id: record.noun_library_id,
        library_name: record.library_name,
        status: checked ? 'ON' : 'OFF',
      });

      setDataSource(dataSource.map((item) => (item.noun_library_id === record.noun_library_id
        ? { ...item, status: checked ? 'ON' : 'OFF' }
        : item)));
    } catch (error) {
      message.error(t('termbase.switchFailed'));
    } finally {
      setLoadId(null);
    }
  };

  // 处理删除术语库
  const handleDelete = async (record: TermBase) => {
    setDeleteLoadingId(record.noun_library_id);
    try {
      await deleteTermBase({
        noun_library_id: record.noun_library_id,
      });
      await fetchTermBaseList(); // 重新获取列表
      message.success(t('termbase.deleteSuccess'));
    } catch (error) {
      message.error(t('termbase.deleteFailed'));
    } finally {
      setDeleteLoadingId(null);
    }
  };

  // 处理源语言变化
  const handleSourceLanguageChange = (value: string) => {
    setSourceLanguage(value);
    const targetLang = form.getFieldValue('target_language');
    if (targetLang === value) {
      form.setFieldValue('target_language', undefined);
    }
  };

  const columns = [
    {
      title: t('termbase.libraryName'),
      dataIndex: 'library_name',
      key: 'library_name',
    },
    {
      title: t('termbase.category'),
      key: 'category',
      render: (record: TermBase) => `${t(`lang.${record.source_language}`)} \t\t - \t\t ${t(`lang.${record.target_language}`)}`,
    },
    {
      title: t('termbase.action'),
      key: 'action',
      render: (_: any, record: TermBase) => (
        <Space size="middle">
          <a onClick={() => handleEdit(record)}>{t('termbase.edit')}</a>
          <Link to={`/termbase/${record.noun_library_id}`}>{t('termbase.view')}</Link>
          <Popconfirm
            title={t('termbase.deleteWarn')}
            onConfirm={() => handleDelete(record)}
            okText={t('common.delete')}
            cancelText={t('common.cancel')}
          >
            <a style={{
              color: deleteLoadingId === record.noun_library_id ? '#1890ff' : 'red',
            }}
            >
              {deleteLoadingId === record.noun_library_id ? <LoadingOutlined /> : t('common.delete')}
            </a>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: t('termbase.switch'),
      key: 'status',
      render: (record: TermBase) => (
        <Space>
          <Switch
            loading={loadId === record.noun_library_id}
              checked={record.status === 'ON'}
              onChange={(checked) => handleSwitchChange(record, checked)}
            />
        </Space>
      ),
    },
  ];

  return (
    <div className={css.pageContent}>
      <div className={css.pageHeader}>
        <div className={css.titleSection}>
          <div>
            <Breadcrumb className={css.breadcrumb} items={[{ title: t('termbase.name') }]} />
            <div className={css.description}>
              {t('termbase.description')}
            </div>
          </div>
          <Space className={css.buttonGroup}>
            <Button className={css.createButton} type="primary" icon={<PlusOutlined />} onClick={handleCreate}>
              {t('termbase.create')}
            </Button>
          </Space>
        </div>
      </div>

      <Table
        loading={pageLoading}
        className={css.contentTable}
        columns={columns}
        dataSource={dataSource}
        rowKey="noun_library_id"
      />

      <Modal
        title={editingId ? t('termbase.termBaseEdit') : t('termbase.termBaseCreate')}
        open={isModalOpen}
        onOk={handleModalOk}
        onCancel={() => setIsModalOpen(false)}
        confirmLoading={modalIsLoading}
      >
        <Form form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="library_name"
            label={t('termbase.libraryName')}
            rules={[{ required: true, message: t('termbase.libraryNameRequired') }, {
              max: 100,
              message: t('termbase.libraryNameMax'),
            }]}
          >
            <Input placeholder={t('termbase.libraryNamePlaceholder')} />
          </Form.Item>

          <Form.Item
            name="source_language"
            label={t('termbase.sourceLanguage')}
            hidden={!!editingId}
            rules={[{ required: true, message: t('termbase.sourceLanguageRequired') }]}
          >
            <Select
              placeholder={t('termbase.sourceLanguagePlaceholder')}
              onChange={handleSourceLanguageChange}
            >
              {SUPPORTED_LANGUAGES.map((lang) => (
                <Select.Option key={lang} value={lang}>
                  {t(`lang.${lang}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="target_language"
            label={t('termbase.targetLanguage')}
            hidden={!!editingId}
            rules={[{ required: true, message: t('termbase.targetLanguageRequired') }]}
          >
            <Select
              placeholder={t('termbase.targetLanguagePlaceholder')}
              disabled={!sourceLanguage}
            >
              {SUPPORTED_LANGUAGES.filter((lang) => lang !== sourceLanguage).map((lang) => (
                <Select.Option key={lang} value={lang}>
                  {t(`lang.${lang}`)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {!!editingId && (
            <Form.Item
              name="status"
              hidden={!!editingId}
              label="状态"
              valuePropName="checked"
              initialValue={false}
          >
              <Switch />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default TermBasePage;
